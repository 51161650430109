import axios from 'axios';
import config from '../config';
import toCamelCase from '../utils/camelCase';
import { logToNewRelic } from '../utils/logNewRelicCustom';
import { LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE } from '../actions/hydration/constants';

const saveRecoverableFailure = (validationMessages) => ({
  type: 'GCC_CONFIGURATOR/SAVE_RECOVERABLE_FAILURE',
  validationMessages,
});
export const mapChoicesToSkus = (siteId, productId, storeId, choices) => {
  const url = `${config.eleanorBaseUrl}/api/MapChoicesToSkusWithMultipliers`;

  const json = {
    siteId,
    productId,
    storeId,
    choices,
  };

  return axios
    .post(url, json)
    .then((response) => response.data)
    .then(toCamelCase)
    .catch((error) => {
      LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_UNAVALIABLE, { configuratorUnavailable: true });
      LIFE_CYCLE_EVENT_BUS.trigger(LIFE_CYCLE_EVENT_CONFIGURATOR_LOADING, false);
      // if (error.name && error.name === 'SaveError') {
      //   dispatch(saveRecoverableFailure(error.validationErrors));
      // }
      logToNewRelic('map-choices-to-skus-error', error);
      throw error;
    });
};

export const getSkuPricing = (sku, skuType, productId) => {
  const url = `${config.eleanorBaseUrl}/pricing/GetRetailPriceAndCost/sku/${sku}/skuType/${skuType}/productId/${productId}`;
  const promise = (sku && skuType && productId) ? axios.get(url, '') : Promise.resolve;

  return promise
    .then((response) => response.data)
    .catch((error) => {
      logToNewRelic('get-sku-pricing-error', error);
      throw error;
    });
};

export const mapChoiceSkuAvailability = (productId, storeId) => {
  const url = `${config.eleanorBaseUrl}/api/CheckProductChoiceAndSkuAvailability`;
  const params = { productId, storeId };
  const promise = axios.get(url, { params });
  return promise
    .then((response) => response.data)
    .catch((error) => {
      logToNewRelic('map-choice-sku-availability-error', error);
      throw error;
    });
};
