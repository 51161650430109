/* eslint-disable lodash/prefer-constant */
/* This template mostly is the copy from  Eughteenth inch Drop down  Component */

import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import camelCase from 'lodash/camelCase';
import HowToMeasure from '../../../components/HowToMeasure';
import ChoiceSectionHeaderV2 from '../../../components/ChoiceSectionHeader/v2';
import ConfiguratorTemplate from '../ConfiguratorTemplate';
import SixteenthInchDropdown from '../../../components/SixteenthInchDropdown';

const EighthInchDropdownWrapper = styled.div`
  margin: -10px -10px 0px -10px;
  @media (max-width: 767px) {
    div[class^="styles__Wrapper"] {
      min-width: 50%;
      box-sizing: border-box;
    }
  }
`;

const WithCustomSizing = (props) => {
  const {
    attributes,
    option,
    choices: [choice],
    onChoiceSelected,
  } = props;

  const getDefinition = () => attributes[camelCase('HowToMeasure')];// Create bool from string
  const { defaultSizesWidthHeight = '' } = attributes || '';

  const [defaultWidth, defaultHeight] = defaultSizesWidthHeight && defaultSizesWidthHeight.split(':');

  const handleChange = useCallback((inch, eighth) => {
    const choiceValue = (Number.isNaN(inch) ? Number(choice.minValue) : inch)
    + (Number.isNaN(eighth) ? Number(0) : eighth);

    onChoiceSelected(option.id, choice.id, choiceValue);
  }, [option, choice, onChoiceSelected]);

  // width
  useEffect(() => {
    if (option.optionType === 'Width') {

      const defaultWidthOrMinValue = defaultWidth || choice.minValue;

      if (+(choice.value) > 0 && +(choice.value) !== +(choice.minValue)) {
        Promise.resolve(onChoiceSelected(option.id, choice.id, choice.value));
        return () => false;
      }

      Promise.resolve(onChoiceSelected(option.id, choice.id, +(defaultWidthOrMinValue)));
    }
    return () => true;
  }, [defaultWidth]);

  // height
  useEffect(() => {
    if (option.optionType === 'Height') {
      const defaultHeightOrMinValue = defaultHeight || choice.minValue;

      if (+(choice.value) > 0 && +(choice.value) !== +(choice.minValue)) {
        Promise.resolve(onChoiceSelected(option.id, choice.id, choice.value));
        return () => false;
      }

      Promise.resolve(onChoiceSelected(option.id, choice.id, +(defaultHeightOrMinValue)));
    }
    return () => true;
  }, [defaultHeight]);

  return (
    <>
      {option && (option.optionType === 'Width' || option.tags.includes('HowToMeasure')) && (
        <HowToMeasure
          label={option && option.shortLabel}
          definitionHeader="How to Measure:"
          definitionMarkdown={getDefinition()}
        />
      )}
      <div style={{ marginTop: '20px' }}>

        <ChoiceSectionHeaderV2 label={option && option.shortLabel} />

        {/* Styling to adjust left and right margins */}
        <EighthInchDropdownWrapper>
          <SixteenthInchDropdown
            value={choice.value}
            minValue={Math.floor(choice.minValue)}
            maxValue={Math.floor(choice.maxValue)}
            onChange={handleChange}
          />

        </EighthInchDropdownWrapper>
      </div>

    </>
  );
};

WithCustomSizing.propTypes = {
  option: PropTypes.object.isRequired,
  choices: PropTypes.array.isRequired,
  attributes: PropTypes.object.isRequired,
  onChoiceSelected: PropTypes.func.isRequired,
};

export default ConfiguratorTemplate(WithCustomSizing);
